import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
// import {Http, HttpModule} from '@angular/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { rootRouterConfig } from "./app.routes";
import { AppComponent } from "./app.component";
import * as $ from "jquery";
import { HttpClientModule } from "@angular/common/http";
import { NgxSmartModalModule } from "ngx-smart-modal";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    // HttpModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    RouterModule.forRoot(rootRouterConfig, {
      useHash: false,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled"
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
